const removeClass = (elementsList, classToRemove) => {
  elementsList.forEach(element=>{
    element.classList.remove(classToRemove);
  })
}

const productDescriptionEvents = () => {
  const allSingleBoxes = document.querySelectorAll('.wooProductDescription__single');

  allSingleBoxes.forEach(element => {
    element.addEventListener('click', () => {
      if(!element.classList.contains('active')) {
        removeClass(allSingleBoxes, 'active');
        element.classList.add('active');
      } else {
        element.classList.remove('active');
      }
    })
  })
}




export const productDescriptionScript = () => {
  document.addEventListener( 'DOMContentLoaded', function () {
    productDescriptionEvents();
  })
}